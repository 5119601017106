:root {
  --main-bg-color: #07060C;
  --text-color: #ffffff;
  --muted-color: rgba(255,255,255,0.64);
  --border-color: rgba(255,255,255,0.1);

  --purple: #CC4DDF;
  --danger: #E02351;

  --gradient: linear-gradient(96.37deg, var(--purple) 13.48%, #64C0E0 34.4%, #1E46E8 100.92%);

  --font-refular: 'Montserrat-Regular', sans-serif;
  --font-medium: 'Montserrat-Medium', sans-serif;
  --font-bold: 'Montserrat-Bold', sans-serif;

  --transition: all .3s ease;
  --transition-1s: all 1s ease;
}
