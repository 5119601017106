#section_2 {
  margin-top: -70px;
}

.section_2-item {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.section_2-item:before {
  content: '';
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background: var(--gradient);
  filter: blur(50px);
  border-radius: 40px;
  display: none;
}
.section_2-pagination {
  position: absolute;
  right: 24px;
  bottom: 50px;
}

#section_2 .col-12:first-child .section_2-item-content {
  background: var(--purple);
}
#section_2 .col-12:first-child .section_2-item:before {
  display: block;
}
.section_2-item-content {
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box, var(--gradient) border-box;
  border-radius: 40px;
  border: 1px solid transparent;
  padding: 32px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
  flex: 1;
}
.section_2-item .bold {
  padding-bottom: 40px;
}

@media only screen and (max-width: 992px) {
  #section_2 {
    margin-top: 40px;
  }
  .section_2-item-content {
    padding: 16px;
    padding-bottom: 52px;
    border-radius: 24px;
  }
  .section_2-item .bold {
    padding-bottom: 24px;
  }
  .section_2-pagination {
    bottom: 32px;
    right: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .section_2-item {
    margin-bottom: 12px;
    height: auto;
  }

}

