@media only screen and (max-width: 1700px) {
  .section-title {
    font-size: 32px;
    line-height: 40px;
  }

  body, .form-control {
    font-size: 18px;
    line-height: 28px;
  }
  .bold-16 {
    font-size: 14px;
    line-height: 17px;
  }
  main {
    padding: 0 40px 24px;
  }
  .section-title-icon svg {
    height: 36px;
    width: 36px;
  }
}

@media only screen and (max-width: 1200px) {
  body, .form-control {
    font-size: 16px;
    line-height: 24px;
  }
  .bold-16 {
    font-size: 12px;
    line-height: 15px;
  }
  main {
    padding: 0 24px 24px;
  }

}

@media only screen and (max-width: 992px) {
  .btn {
    font-size: 16px;
    line-height: 24px;
  }
  main {
    padding-top: 120px;
  }
  html {
    overflow-x: hidden;
  }
  body {

    font-size: 14px;
    line-height: 21px;
  }
  .btn-gradient {
    height: 48px;
    font-size: 16px;
    max-width: 100%;
  }
  .form-group.mb-4 {
    margin-bottom: 12px !important;
  }
  footer {
    margin-top: 0;
    height: 72px;
    font-size: 14px;
  }
  section {
    margin-top: 40px;
  }
  .section-title.mb-4 {
    margin-bottom: 16px !important;

  }
  .section-title {
    font-size: 24px;
    line-height: 32px;
  }
  .section-title-icon svg {
    height: 30px;
  }
  .section-title-icon {
    top: -2px;
  }

}

@media only screen and (max-width: 768px) {
  main {
    padding: 120px 16px 24px;
  }
  .section-title {
    font-size: 16px;
    line-height: 24px;
  }

  .section-title-icon {
    margin-right: 8px;
  }
  html[dir='rtl'] .section-title-icon{
    margin-right: 0;
    margin-left: 8px;
  }
  .section-title-icon svg {
    height: 24px;
    width: 24px;
  }
  .slick-dots {
    margin-left: 16px;
  }
  html[dir='rtl'] .slick-dots {
    margin-left: 0;
    margin-right: 16px;
  }
}

@media only screen and (max-width: 576px) {

}

@media only screen and (max-width: 375px) {

}

@media (min-width: 992px) {
  html[dir='rtl'] .pe-lg-3 {
    padding-right: 0;
    padding-left: 1rem !important;
  }
}