#section_3 .section-title {
  max-width: 700px;
}

.section-3-platforms {
  margin-top: 32px;
  display: flex;
  align-items: center;
  min-height: 20px;
  flex-wrap: wrap;
}

.section-3-platforms img {
  height: 100%;
  width: auto;
  object-fit: contain;
}

.slideshow {
  position: relative;
  overflow: hidden;
  height: 184px;
}

.slideshow-wrap {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  animation: moveSlideshow 600s linear infinite;
  display: flex;
}

@keyframes moveSlideshow {
  50% {
    -webkit-transform: translateX(calc(-100% + 90vw));
  }
  100% {
    -webkit-transform: translateX(100px);
  }
}

.section-3-slideshow {
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 52px -100px 0;
}

.section-3-slideshow:after {
  content: '';
  position: absolute;
  right: -10px;
  top: 0;
  bottom: 0;
  width: 90px;
  background: linear-gradient(to left, var(--main-bg-color) 10%, transparent 100%);
  pointer-events: none;
}

.section-3-slideshow:before {
  content: '';
  position: absolute;
  left: -10px;
  top: 0;
  bottom: 0;
  width: 90px;
  background: linear-gradient(to right, var(--main-bg-color) 10%, transparent 100%);
  z-index: 1;
  pointer-events: none;
}

.slideshow-item-wrap {
  height: 184px;
  padding: 0 6px;
  display: flex;
  justify-content: center;
}

.slideshow-item {
  border-radius: 12px !important;
  background: rgba(255, 255, 255, 0.1);
  display: flex !important;
  align-items: center;
  justify-content: center;
  padding: 0 64px !important;
  min-width: 220px;
  height: 100%;
  cursor: default !important;
}


.slideshow-item:hover {
  background: var(--gradient);
}

.slideshow-item:hover path {
  fill-opacity: 1;
}

.section-3-platforms-item {
  display: flex;
  height: 100%;
  align-items: center;
  margin-right: 20px;
  /*cursor: pointer;*/
  margin-bottom: 8px;
}

.section-3-platforms-item svg {
  height: 16px;
  width: auto;
}

.section-3-platforms-item:hover path {
  fill-opacity: 1;
}

@media only screen and (max-width: 1700px) {
  .section-3-slideshow {
    width: 100vw;
    overflow: hidden;
    margin: 24px -40px 0;
  }

  .slideshow-item-wrap {
    height: 100px;
  }

  .slideshow-item-wrap svg {
    /*max-width: 80%;*/
    /*max-height: 80%;*/
    height: 40px;
    width: auto;
  }

  .slideshow {
    height: 100px;
  }
  .slideshow-item {
    padding: 0 32px!important;
  }
}

@media only screen and (max-width: 1200px) {
  .slideshow-item {
    padding: 0 24px!important;
  }
  .section-3-slideshow {
    margin: 16px -24px 0;
  }

  .slideshow-item-wrap {
    height: 60px;
  }

  .slideshow-item-wrap svg {
    /*max-width: 80%;*/
    /*max-height: 80%;*/
    height: 30px;
  }

  .slideshow {
    height: 60px;
  }
}

@media only screen and (max-width: 992px) {
  .slideshow-item {
    min-width: 100px;
    padding: 0 16px !important;
  }

}
