.form-group {
  display: flex;
  flex-direction: column;
  position: relative;
}

.form-control-wrap {
  position: relative;
  display: flex;
  align-items: center;
  border-radius: 16px;
}

.input-icon {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
  width: 56px;
  height: 56px;
  position: absolute;
  left: 4px;
  top: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-control {
  width: 100%;
  font-size: 20px;
  font-family: var(--font-bold);
  color: #fff;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  min-height: 64px;
  z-index: 1;
}

.form-control::placeholder {
  font-size: 20px;
  font-family: var(--font-refular);
  color: rgba(255, 255, 255, 0.4);
}

.form-control:focus {
  color: #fff;
  outline: 0;
  box-shadow: none;
  background-color: rgba(255, 255, 255, 0.1);
  border: 1px solid transparent;
}

.form-control-gradient {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  filter: blur(50px);
  border-radius: 16px;
  background: var(--gradient);
  opacity: 0;
  pointer-events: none;
  transition: var(--transition);
}

.form-control:focus + .form-control-border + .form-control-gradient {
  opacity: 1;
}

.form-control-border {
  position: absolute;
  left: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
  opacity: 0;
  pointer-events: none;
  border-radius: 16px;
}

.form-control:focus + .form-control-border {
  opacity: 1;
}

.form-control-icon {
  padding-left: 72px;
}

.form-control:focus + .form-control-border + .form-control-gradient + .input-icon path {
  fill-opacity: 1;
  stroke-opacity: 1;
}
.form-group-error {
  position: absolute;
  right: 0;
  color: var(--danger);
  font-size: 16px;
}
.form-group-file {
  position: relative;
  font-size: 20px;

  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  min-height: 64px;
  padding: 12px;
  padding-bottom: 4px;
}

.form-file-label {
  color: var(--muted-color);
  font-family: var(--font-bold);
  cursor: pointer;
  position: relative;
  height: 48px;
  border-radius: 40px;
  background: linear-gradient(#36353A, #36353A) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
  display: flex;
  align-items: center;
  padding: 0 20px 0 12px;
}

.form-file-label:hover {
  color: var(--text-color)
}

.form-file-label:hover path {
  stroke-opacity: 1;
}

.form-group-file input {
  opacity: 0;
  position: absolute;
  pointer-events: none;
}

.form-file-list-item {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 24px;
  display: flex;
  align-items: center;
  overflow: hidden;
  font-size: 16px;
  height: 40px;
  color: var(--muted-color);
  padding: 0 8px 0 16px;
  max-width: 202px;
  margin-bottom: 8px;
  margin-right: 8px;
  line-height: 20px;
  cursor: default;
}

.form-file-list-item:hover {
  color: var(--text-color)
}

.form-file-list-item:hover path {
  fill-opacity: 1;
}

.form-group-file-placeholder {
  font-family: var(--font-refular);
  color: rgba(255, 255, 255, 0.4);
}

.form-group-file .input-icon + .form-group-file-placeholder {
  padding-left: 60px;
}

.form-group-file .input-icon {
  left: 3px;
  top: 3px;
}

textarea.form-control {
  resize: none;
  padding-top: 16px;
  padding-bottom: 16px;
  min-height: 124px;
}

.input-pass-eye {
  cursor: pointer;
  position: absolute;
  right: 16px;
  width: 32px;
  height: 32px;
  z-index: 1;
}

.form-password-forgot {
  margin-right: 16px;
  cursor: pointer;
  font-size: 16px;
  color: var(--purple);
  transition: var(--transition);
}

.form-password-forgot:hover {
  text-decoration: underline;
}

.input-file__progress {
  min-width: 24px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: rgba(255, 255, 255, 0.1);
}

.form-group.error .form-control, .form-group.error  .form-group-file {
  border-color: var(--danger);
}

.form-file-label-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 16px;
  padding-right: 8px;
}

.form-check-input {
  display: none;
}
.form-check-custom svg {
  display: none;
}
.form-check-input:checked + .form-check-custom svg{
  display: block;
}
.form-check {
  width: 32px;
  height: 32px;
  min-width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-check-custom {
  height: 100%;
  width: 100%;
  background: linear-gradient(var(--main-bg-color), var(--main-bg-color)) padding-box, var(--gradient) border-box;
  border: 1px solid transparent;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  cursor: pointer;
  transition: var(--transition);
}

html[dir='rtl'] .form-control-icon {
  padding-left: 0px;
  padding-right: 72px;
}
html[dir='rtl'] .form-group-file .input-icon + .form-group-file-placeholder {
  padding-left: 0px;
  padding-right: 60px;
}
html[dir='rtl'] .form-file-label-wrap {
  padding-right: 0;
  padding-left: 8px;
}
html[dir='rtl'] .input-icon {
  left: auto;
  right: 4px;
}

@media only screen and (max-width: 992px) {
  .form-file-label-wrap {
    padding-right: 0px;
  }
  html[dir='rtl'] .form-file-label-wrap {
    padding-right: 0;
    padding-left: 0;
  }
  .form-group-file {
    /*padding: 8px;*/
    padding: 8px 8px 4px;
  }

  .form-control {
    min-height: 48px;
  }

  .input-icon {
    width: 40px;
    height: 40px;
  }

  .input-icon svg {
    width: 24px;
  }

  .form-control-icon {
    padding-left: 52px;
  }
  html[dir='rtl'] .form-control-icon {
    padding-left: 0;
    padding-right: 52px;
  }
  textarea.form-control {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .form-control::placeholder, .form-group-file-placeholder, .form-group-file {
    font-size: 14px;
  }

  .form-file-label {
    font-size: 14px;
    height: 32px;
    border-radius: 40px;
  }

  .form-file-label svg {
    width: 18px;
  }

  .form-password-forgot {
    font-size: 14px;
  }
  .form-group-error {
    font-size: 14px;
  }
}