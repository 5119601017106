@font-face {/*400*/
  font-family: 'Montserrat-Regular';
  src: url('../fonts/Montserrat-Regular.eot');
  src: url('../fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Regular.woff') format('woff'),
  url('../fonts/Montserrat-Regular.ttf') format('truetype'),
  url('../fonts/Montserrat-Regular.svg#webfont') format('svg');
}
@font-face {/*500*/
  font-family: 'Montserrat-Medium';
  src: url('../fonts/Montserrat-Medium.eot');
  src: url('../fonts/Montserrat-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Medium.woff') format('woff'),
  url('../fonts/Montserrat-Medium.ttf') format('truetype'),
  url('../fonts/Montserrat-Medium.svg#webfont') format('svg');
}
@font-face {/*600*/
  font-family: 'Montserrat-SemiBold';
  src: url('../fonts/Montserrat-SemiBold.eot');
  src: url('../fonts/Montserrat-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-SemiBold.woff') format('woff'),
  url('../fonts/Montserrat-SemiBold.ttf') format('truetype'),
  url('../fonts/Montserrat-SemiBold.svg#webfont') format('svg');
}

@font-face {/*700*/
  font-family: 'Montserrat-Bold';
  src: url('../fonts/Montserrat-Bold.eot');
  src: url('../fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Bold.woff') format('woff'),
  url('../fonts/Montserrat-Bold.ttf') format('truetype'),
  url('../fonts/Montserrat-Bold.svg#webfont') format('svg');
}

@font-face {/*800*/
  font-family: 'Montserrat-ExtraBold';
  src: url('../fonts/Montserrat-ExtraBold.eot');
  src: url('../fonts/Montserrat-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-ExtraBold.woff') format('woff'),
  url('../fonts/Montserrat-ExtraBold.ttf') format('truetype'),
  url('../fonts/Montserrat-ExtraBold.svg#webfont') format('svg');
}

@font-face {/*900*/
  font-family: 'Montserrat-Black';
  src: url('../fonts/Montserrat-Black.eot');
  src: url('../fonts/Montserrat-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/Montserrat-Black.woff') format('woff'),
  url('../fonts/Montserrat-Black.ttf') format('truetype'),
  url('../fonts/Montserrat-Black.svg#webfont') format('svg');
}

/* -------------------------- */
